import React from "react";
import Discount from "../../components/discount/Discount";
import Explore from "../../components/explore/Explore";
import Featurebox from "../../components/featurebox/Featurebox";
import Footer from "../../components/footer/Footer";
import Header from '../../components/header/Header';
import ScrollToTop from "../../components/includes/scroll-to-top/ScrollToTop";
import Main from "../../components/main/Main";
import Reviews from "../../components/reviews/Reviews";
import Subscribe from "../../components/subscribe/Subscribe";


 const Home = () => {
  return (
    <div>
      <Discount />
      <Header />
      <Main />
      <ScrollToTop />
      <Featurebox />
      <Explore />
      <Reviews />
      <Subscribe />
      <Footer />
    </div>
  )
}

export default Home
