import React from "react";
import Booking from "../../components/booking/Booking";
import Discount from "../../components/discount/Discount";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import ScrollToTop from "../../components/includes/scroll-to-top/ScrollToTop";
import Subscribe from "../../components/subscribe/Subscribe";

import { ContactMain, ContactRequest, RequestHead, RequestImage, RequestImg, RequestInfo, RequestPara } from "./contactUs.styles";

 const ContactUs = () => {
  return (
    <ContactMain>
      <Discount />
      <Header />
      <ContactRequest>
          <RequestInfo>
              <RequestHead>
                Call today to schedule an appointment.
              </RequestHead>
              <RequestPara>
                Do not keep to yourself, kindly contact us for help, reach us directly with
                any questions, comments, or scheduling inquiries you may have.
                <br />
                <br />
                Fill out the patient form below to book an appointment, let us help save time, your health and your life.
              </RequestPara>
          </RequestInfo>
          <RequestImage>
          <RequestImg src="https://res.cloudinary.com/dftu6mjmt/image/upload/v1693778382/Brightlife/pexels-moose-photos-1587014_xqajxr.jpg" alt="Don't be Depressed" />
          </RequestImage>
      </ContactRequest>
      <ScrollToTop />
      <Booking />
      <Subscribe />
      <Footer />
    </ContactMain>
  )
}

export default ContactUs

// username: bless@brightlifeenhancement.org
// password: bles@brightlife
