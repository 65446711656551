import { BiInjection } from "react-icons/bi";
import {
  TbCircuitCapacitorPolarized,
  TbBorderSides,
  TbLicense,
} from "react-icons/tb";
import {
  BsPersonLinesFill,
  BsFillEmojiAngryFill,
  BsPersonFillExclamation,
} from "react-icons/bs";
import {
  GiDiscobolus,
  GiTornadoDiscs,
  GiDistraction,
  GiPersonInBed,
  GiNurseFemale,
} from "react-icons/gi";
import { AiFillAliwangwang } from "react-icons/ai";
import {
  MdSentimentVeryDissatisfied,
  MdOutlineSportsKabaddi,
} from "react-icons/md";
import { RiDislikeFill } from "react-icons/ri";
import { FaPersonBooth, FaPeopleArrows } from "react-icons/fa";
import { MdEmojiPeople } from "react-icons/md";
import { IoIosPeople } from "react-icons/io";
import { IoPeopleCircleOutline, IoManSharp } from "react-icons/io5";
import { MdPsychologyAlt } from "react-icons/md";
import { MdOutlineSupportAgent } from "react-icons/md";

export const sliderItems = [
  {
    id: 0,
    img: "https://res.cloudinary.com/dftu6mjmt/image/upload/v1678355677/Brightlife/workpeople_otr8cj.jpg",
  },
  {
    id: 1,
    img: "https://res.cloudinary.com/dftu6mjmt/image/upload/v1693777802/Brightlife/pexels-antoni-shkraba-7579306_hkck0p.jpg",
  },
  {
    id: 2,
    img: "https://res.cloudinary.com/dftu6mjmt/image/upload/v1678349787/Brightlife/nun_lod5rv.jpg",
  },
];

export const exploreItems = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dftu6mjmt/image/upload/v1678349822/Brightlife/Finance_x1aqd8.jpg",
    title: "Finance Management",
    altName: "Finance Management",
  },
  {
    id: 2,
    img: "https://res.cloudinary.com/dftu6mjmt/image/upload/v1678355663/Brightlife/Tasks_ytjlan.jpg",
    title: "Completing Tasks & Chores",
    altName: "Completing Tasks & Chores",
  },
  {
    id: 3,
    img: "https://res.cloudinary.com/dftu6mjmt/image/upload/v1678355646/Brightlife/Social_ssvcde.jpg",
    title: "Social Skills",
    altName: "Social Skills",
  },
  {
    id: 4,
    img: "https://res.cloudinary.com/dftu6mjmt/image/upload/v1678355574/Brightlife/Organisation_d6udfg.jpg",
    title: "Organizational Skills",
    altName: "Organizational Skills",
  },
  {
    id: 5,
    img: "https://res.cloudinary.com/dftu6mjmt/image/upload/v1678349848/Brightlife/Job_v69zox.jpg",
    title: "Job Readiness",
    altName: "Job Readiness",
  },
  {
    id: 6,
    img: "https://res.cloudinary.com/dftu6mjmt/image/upload/v1678355662/Brightlife/School_m4imvl.jpg",
    title: "School Readiness",
    altName: "School Readiness",
  },
  {
    id: 7,
    img: "https://res.cloudinary.com/dftu6mjmt/image/upload/v1678355679/Brightlife/TimeMan_mfc09x.jpg",
    title: "Time Management",
    altName: "Time Management",
  },
  {
    id: 8,
    img: "https://res.cloudinary.com/dftu6mjmt/image/upload/v1678349846/Brightlife/Hygiene_y2c5zj.jpg",
    title: "Good Hygiene Practices",
    altName: "Good Hygiene Practices",
  },
];

export const reviewItems = [
  {
    id: 1,
    name: "Morenikeji Precious",
    review:
      "Every chicken bite reminds me of a perfect, well seasoned food! I love the menu, especially the porridge 😩🥰",
    date: "13th April, 2022.",
    rating: 8.0,
    img: "../img/prec.jpeg",
  },
  {
    id: 2,
    name: "Eunice Akinmola",
    review:
      "Delicious meal tastes likes mama’s kitchen, Really nice, well seasoned, tender.",
    date: "5th May, 2022.",
    rating: 9.5,
    img: "../img/mola.jpeg",
  },
  {
    id: 3,
    name: "Morolakemi Kouassi",
    review:
      "Very delicious and I love the way it looks very presentable and sumptuous 🥰",
    date: "1st May, 2022.",
    rating: 8.9,
    img: "../img/coco3.png",
  },
  {
    id: 4,
    name: "Adegoke Elizabeth ",
    review:
      "Very nice, they have delicious delicacies 🥰, (you should try it please)",
    date: "20th April, 2022.",
    rating: 7.5,
    img: "../img/mola.jpeg",
  },
  {
    id: 5,
    name: "Morolakemi Kouassi",
    review:
      "Very delicious and I love the way it looks very presentable and sumptuous 🥰",
    date: "1st May, 2022.",
    rating: 8.9,
    img: "../img/prec.jpeg",
  },
  {
    id: 6,
    name: "Adegoke Elizabeth ",
    review:
      "Very nice, they have delicious delicacies 🥰, (you should try it please)",
    date: "20th April, 2022.",
    rating: 7.5,
    img: "../img/mola.jpeg",
  },
];

//SERVICES
export const serviceItems = [
  {
    id: 1,
    url: "https://res.cloudinary.com/dftu6mjmt/image/upload/v1678349731/Brightlife/feature4_sclbra.jpg",
    imgId: "img1",
    titleId: "title1",
    title: "On-Site and Off-Site Services",
  },
  {
    id: 2,
    url: "https://res.cloudinary.com/dftu6mjmt/image/upload/v1678356717/Brightlife/pexels-rodnae-productions-9064695_gkdkly.jpg",
    imgId: "img2",
    titleId: "title2",
    title: "Case Management / Coordination",
  },
  {
    id: 3,
    url: "https://res.cloudinary.com/dftu6mjmt/image/upload/v1678349720/Brightlife/feature1_sio4xo.jpg",
    imgId: "img3",
    titleId: "title3",
    title: "Behavioural Management",
  },
  {
    id: 4,
    url: "https://res.cloudinary.com/dftu6mjmt/image/upload/v1678349756/Brightlife/feature3_hplsdb.jpg",
    imgId: "img4",
    titleId: "title4",
    title: "Personality Disorders",
  },
];

//PARTICIPANTS
export const participantItems = [
  {
    id: 1,
    icon: <BiInjection />,
    title: "Substance use disorders",
  },
  {
    id: 2,
    icon: <BsFillEmojiAngryFill />,
    title: "Anxiety disorders",
  },
  {
    id: 3,
    icon: <BsPersonLinesFill />,
    title: "Personality disorders",
  },
  {
    id: 4,
    icon: <TbCircuitCapacitorPolarized />,
    title: "Bipolar I and Bipolar II Disorders",
  },
  {
    id: 5,
    icon: <GiDiscobolus />,
    title: "Autism",
  },
  {
    id: 6,
    icon: <GiDistraction />,
    title: "PTSD",
  },
  {
    id: 7,
    icon: <RiDislikeFill />,
    title: "Depression and treatment-resistance depression",
  },
  {
    id: 8,
    icon: <AiFillAliwangwang />,
    title: "ADHD",
  },
  {
    id: 9,
    icon: <FaPeopleArrows />,
    title: "Adjustment disorders",
  },
  {
    id: 10,
    icon: <GiPersonInBed />,
    title: "Schizophrenia disorders",
  },
  {
    id: 11,
    icon: <FaPersonBooth />,
    title: "Unspecified schizophrenia spectrum",
  },
  {
    id: 12,
    icon: <GiTornadoDiscs />,
    title: "Co-occuring disorders",
  },
  {
    id: 13,
    icon: <BsPersonFillExclamation />,
    title: "Delusional disorder",
  },
  {
    id: 14,
    icon: <TbBorderSides />,
    title: "Borderline personality disorder",
  },
  {
    id: 15,
    icon: <MdSentimentVeryDissatisfied />,
    title: "Major depressive disorder",
  },
];

//Programs
export const miniprogramsItems = [
  {
    id: 1,
    title: "Rehabilitation Readiness/Assessment",
  },
  {
    id: 2,
    title: "Social Security Income (SSI)",
  },
  {
    id: 3,
    title: "Temporary Cash Assistance (TCA)",
  },
  {
    id: 4,
    title: "Case Management/Coordination",
  },
  {
    id: 5,
    title: "Behavioral & Symptom Management",
  },
  {
    id: 6,
    title: "Supplemental Nutrition Assistance Program (SNAP)",
  },
  {
    id: 7,
    title:
      "Enrichment/Support(such as life skills training and maintenance of housing: budgeting, etc).",
  },
  {
    id: 8,
    title: "Assistance with Scheduling and Maintaining Appointments",
  },
  {
    id: 9,
    title: "Coordination with Vocational Rehabilitation Services",
  },
  {
    id: 10,
    title: "Psycho-Education to assist clients with understanding their diagnoses",
  }
];

export const maxprogramsItems = [
  {
    id: 1,
    title:
      "Liaison and assistance with Other Services and Care Providers including Hospitals and Physicians",
  },
  {
    id: 2,
    title: "Group Activities such as Workshop and Social Skills/Leisure",
  },
  {
    id: 3,
    title: "Coping & Social Skills",
  },
  {
    id: 4,
    title: "Individual, Family & Couples Therapy",
  },
  {
    id: 5,
    title: "Rehabilitation/Treatment Compliance",
  },
  {
    id: 6,
    title: "Assistance with Maintaining Personal Hygiene",
  },
  {
    id: 7,
    title: "Crisis Intervention & Conflict Resolution Skills",
  },
  {
    id: 8,
    title: "Address Health Conditions",
  },
  {
    id: 9,
    title: "Medication Monitoring of Self-Administration",
  },
  {
    id: 10,
    title: "Intensive outpatient program",
  },
];

export const coreItems = [
  {
    id: "one",
    title: "Interagency Collaboration",
    url: "https://res.cloudinary.com/dftu6mjmt/image/upload/v1678349692/Brightlife/collabo_xkn0gs.jpg",
    feature:
      "Ensure clear interagency communication through a common language, assessment tools, and quality improvement processes.",
  },
  {
    id: "two",
    title: "Access to care",
    url: "https://res.cloudinary.com/dftu6mjmt/image/upload/v1678349679/Brightlife/care_pl0iex.jpg",
    feature:
      "Ensure PRP services and support are accessible within reasonable timeframes and with flexible schedules and locations. Ensure services that are person-centered, family-driven, community-based and culturally competent.",
  },
  {
    id: "three",
    title: "Prevention",
    url: "https://res.cloudinary.com/dftu6mjmt/image/upload/v1678349787/Brightlife/opposite_deafkr.jpg",
    feature:
      "Ensure early identification and intervention in an effort to find mental health concerns and needs as soon as they appear.",
  },
  {
    id: "four",
    title: "Workforce development",
    url: "https://res.cloudinary.com/dftu6mjmt/image/upload/v1678349775/Brightlife/force_xpum6t.jpg",
    feature:
      "For all adults/transitional adults, Brightlife Enhancement Services' staff will continue to collaborate with case managers to identify workforce needs and training programs.",
  },
  {
    id: "five",
    title: "Evidence-based",
    url: "https://res.cloudinary.com/dftu6mjmt/image/upload/v1678349715/Brightlife/evidence_o0idss.jpg",
    feature:
      "Brightlife Enhancement Services will utilize evidence-based services to ensure quality services",
  },
  {
    id: "six",
    title: "Home and Community development",
    url: "https://res.cloudinary.com/dftu6mjmt/image/upload/v1693777802/Brightlife/pexels-antoni-shkraba-7579306_hkck0p.jpg",
    feature:
      "Provide services and support beyond traditional and office-based programs. Build the capacity for comprehensive home and community-based PRP services and support.",
  },
];

export const focusItems = [
  {
    id: 1,
    icon: <MdEmojiPeople />,
    title: "The individual as 'the whole person'",
  },
  {
    id: 2,
    icon: <IoIosPeople />,
    title: "The family",
  },
  {
    id: 3,
    icon: <IoPeopleCircleOutline />,
    title: "The community",
  },
  {
    id: 4,
    icon: <FaPeopleArrows />,
    title:
      "Collaboration with referral agencies and other mental health care providers",
  },
];

export const staffItems = [
  {
    id: 1,
    icon: <IoManSharp />,
    title: "Program Director",
  },
  {
    id: 2,
    icon: <TbLicense />,
    title: "Licensed Rehabilitation Specialist",
  },
  {
    id: 3,
    icon: <MdOutlineSportsKabaddi />,
    title: "Rehabilitation Counselors",
  },
  {
    id: 4,
    icon: <GiNurseFemale />,
    title: "Licensed Nurse Practitioner",
  },
  {
    id: 5,
    icon: <MdOutlineSupportAgent />,
    title: "Support Staff & Social Workers",
  },
  {
    id: 6,
    icon: <MdPsychologyAlt />,
    title: "Psychiatrists & Psychologists",
  },
];

