import React from "react";
import { BookContainer, BookContainerButton, BookContainerHead, BookContainerInput, BookContainermessage, BookContainersubject, BookContainertext, BookingDiv, ContactAddress, ContactContainer, ContactEmail, ContactHeading, ContactInfo, ContactSpan, ContactTel, Container, WorkInfo, WorkTime } from "./booking.styles";


 const Booking = () => {
  return (
    <BookingDiv>
        <Container>
            <BookContainer>
                <BookContainerHead>Feel free to contact us and we’ll get back to you shortly.</BookContainerHead>
                <BookContainerInput>
                    <BookContainertext
                    type="text"
                    value=""
                    name="Name"
                    placeholder="Name"
                    class="required"
                    />
                    <BookContainertext
                    type="text"
                    value=""
                    name="Phone"
                    placeholder="Phone Number"
                    class="required"
                    />
                </BookContainerInput>
                <BookContainersubject
                type="text"
                value=""
                name="Email"
                placeholder="Email Address"
                class="required"
                />
                <BookContainersubject
                type="text"
                value=""
                name="Appointment Time"
                placeholder="Appointment Time"
                />
                <BookContainersubject
                type="text"
                value=""
                name="Subject"
                placeholder="Subject"
                class="required"
                />
                <BookContainermessage
                type="text"
                value=""
                name="Message"
                placeholder="Message"
                class="required"
                />
                <BookContainerButton className="bg-btn__Submit">
                Book Now
                </BookContainerButton>
            </BookContainer>
            <ContactContainer>
                <ContactInfo>
                    <ContactHeading>BrightLife Enhancement Services</ContactHeading>

                    <ContactAddress>5, Public Square, Suite 428,<br /> Hagerstown, MD 21740.</ContactAddress>

                    <ContactEmail><ContactSpan>Email:</ContactSpan> brightlife0602@gmail.com, info@blesomhc.com</ContactEmail>
                    <div><ContactSpan>Tel & Fax:</ContactSpan> +1(410)988-2626, +1(240)291-9317</div>
                    <ContactTel><ContactSpan>Tel only:</ContactSpan> +1(410)988-2655</ContactTel>
                </ContactInfo>
                <WorkInfo>
                <ContactHeading>Working Hours</ContactHeading>
                    <WorkTime><ContactSpan>Monday - Friday:</ContactSpan> 9AM - 6PM</WorkTime>
                    <WorkTime><ContactSpan>Saturday - Sunday:</ContactSpan> By Appointment Only</WorkTime>
                    <WorkTime><ContactSpan>Emergency Service:</ContactSpan> 24/7</WorkTime>
                </WorkInfo>
            </ContactContainer>
        </Container>
    </BookingDiv>
  )
}

export default Booking
