import React from 'react'
import Discount from '../../components/discount/Discount'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import AboutJumbotron from '../../components/includes/aboutus-jumbotron/AboutUsJumbotron'
import Core from '../../components/includes/core/Core'
import Focus from '../../components/includes/focus/Focus'
import ScrollToTop from '../../components/includes/scroll-to-top/ScrollToTop'
import Subscribe from '../../components/subscribe/Subscribe'

const AboutUs = () => {
  return (
    <div>
      <Discount />
      <Header />
      <AboutJumbotron title={"Rehabilitation Services"} subhead={"Your Mental Wellness Matters"} />
      <ScrollToTop />
      <Core />
      <Focus />
      <Subscribe />
      <Footer />
    </div>
  )
}

export default AboutUs