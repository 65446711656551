import React from 'react';


 function Subscribe() {
  return (
  <>
    <section className='Subscribe-stories'>
      <div className="Subscribe-heading">
          <h2 className="Subscribe-header">
          We support individuals journey to their own happy place
        </h2>
      </div>
     
      <div className="Subscribe-news">
        <div className="Subscribe-video">
            <video className="Subscribe-video__content" autoPlay muted loop>
            <source src="vid/video2.mp4" type="video/mp4" />
            <source src="vid/video.webm" type="video/webm" />
                Your browser is not supported!
            </video>
        </div>
        <div className="Subscribe-letter">
          <h1>Newsletter</h1>
          <div className="Subscribe-letter__div">
            <p className="Subscribe-letter__para"><span>BrightLife Enhancement Services:</span> 5, Public Square, Suite 428, Hagerstown, MD 21740.</p>
              <div><a href="mailto:brightlife0602@gmail.com" className="Subscribe-letter__mail">brightlife0602@gmail.com</a><span>;+1(410)988-2626.</span></div>
          </div>
          <p className="Subscribe-letter__timely">Get timely updates of our products and services you like.</p>
          <div className="Subscribe-letter__Input">
            <input type="text" value="" name="EAddress" placeholder="email" class="required" />
            <button className="Subscribe-btn__Submit">Subscribe</button>
          </div>
        </div>
      </div>
    </section>
  </>
  )
}

export default Subscribe;
