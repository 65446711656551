import React from "react";
import styles from "./focus.module.scss";
import Image from "../../../common/image/Image";
import { focusItems, staffItems } from "../../../assets/data/data";

const Focus = () => {
  return (
    <div>
      <div className={styles["focus--container"]} id="idfocus">
        <div className={styles["focus--top"]}>
          <div className={styles["focus--top__major"]}>
            <h1 className={styles["focus--top__head"]}>Our Focus</h1>
            <div className={styles["focus--top__div"]}></div>
          </div>
          <div className={styles["focus--top__minor"]}>
            <p className={styles["focus--top__para"]}>
              The focus of the Psychiatric Rehabilitation Program (PRP)
              includes:
            </p>
          </div>
        </div>
        <div className={styles["focus--bottom__container"]}>
          <div className={styles["focus--bottom"]}>
            <div className={styles["focus--btm__left"]}>
              <Image
                className={styles["focus--btm__left-img"]}
                url={"https://res.cloudinary.com/dftu6mjmt/image/upload/v1678349747/Brightlife/family_qiopg4.jpg"}
                altName="focus Item"
              />
            </div>
            <div className={styles["focus--btm__right"]}>
              {focusItems.map((el, i) => {
                const { id, title, icon } = el;
                return (
                  <div
                    className={styles["focus--btm__item"]}
                    key={`${i}${id}`}
                  >
                    <div className={styles["focus--btm__icon"]}>
                      <span className={styles["focus--btm__span"]}>{icon}</span>
                    </div>
                    <h3 className={styles["focus--btm__title"]}>{title}</h3>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className={styles["focus--container"]} id="idfocus">
        <div className={styles["focus--top"]}>
          <div className={styles["focus--top__major"]}>
            <h1 className={styles["focus--top__head"]}>Our Staff</h1>
            <div className={styles["focus--top__div"]}></div>
          </div>
          <div className={styles["focus--top__minor"]}>
            <p className={styles["focus--top__para"]}>
              BrightLife Enhancement Services' experiences, qualified, and highly multidisciplinary trained staff includes:
            </p>
          </div>
        </div>
        <div className={styles["focus--bottom__container"]}>
          <div className={styles["focus--bottom"]}>
            <div className={styles["focus--btm__left"]}>
              <Image
                className={styles["focus--btm__left-img"]}
                url={"https://res.cloudinary.com/dftu6mjmt/image/upload/v1693778023/Brightlife/pexels-yan-krukau-8867482_rxovth.jpg"}
                altName="focus Item"
              />
            </div>
            <div className={styles["focus--btm__right"]}>
              {staffItems.map((el, i) => {
                const { id, title, icon } = el;
                return (
                  <div
                    className={styles["focus--btm__item"]}
                    key={`${i}${id}`}
                  >
                    <div className={styles["focus--btm__icon"]}>
                      <span className={styles["focus--btm__span"]}>{icon}</span>
                    </div>
                    <h3 className={styles["focus--btm__title"]}>{title}</h3>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Focus;
