import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-attachment: fixed;
  background-size: cover;
`;

export const Explor = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
`;

export const ExploreHead = styled.h1`
  margin: 3rem 0 1rem 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  color: #373737;
  font-family: urbanist;

  //tab-port
  @media only screen and (max-width: 900px) {
    font-size: 1.2rem;
    margin-right: 2rem;
    width: 80%;
  }
`;

export const ExploreDiv = styled.div`
  position: relative;
  overflow: auto;
`;

export const ExploreGroup = styled.div`
  display: flex;
  width: 100vw;
  animation: moveInBottom 5s ease-in-out;
`;

export const ExploreBranch = styled.div`
display: flex;
flex-direction: column;
margin: 1rem;
`;

export const ExploreImg = styled.img`
height: 11rem;
width: 20rem;
border-radius: 22px;
`;

export const ExploreTitle = styled.div`
  display: flex;
  width: 70%;
`;

export const ExploreSub = styled.h3`
font-size: 1.2rem;
font-weight: 500;
color: #71d321;
width: 20rem;
`;

export const ExploreText = styled.div`
width: 100%;
padding: 1.5rem;
`;

export const ExploreButton = styled.button`
width: 12rem;
height: 3rem;
border: 2px solid #56A913;
border-radius: 10rem;
background-color: #fff;
margin-left: 1rem;
cursor: pointer;
`;

export const ExploreLink = styled.a`
  text-decoration: none;
  color: #000;
`;

// export const ExploreArrow = styled.div`
//   width: 50px;
//   height: 50px;
//   background-color: #fff7f7;
//   color: #71d321;
//   border-radius: 50%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: ${(props) => props.direction === "left" && "10px"};
//   right: ${(props) => props.direction === "right" && "10px"};
//   margin: auto;
//   cursor: pointer;
//   opacity: 0.5;
//   z-index: 2;
// `;

export const TextPara = styled.p`
font-size: 1.1rem;
color: #373737;
// text-align: center;
width: 100%;
`;


export const Amenities = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
`;

export const AmenitiesHead = styled.h3`
  font-family: El Messiri;
  font-size: 1.8rem;
  color: #000;

    //tab-port
    @media only screen and (max-width: 900px) {
    font-size: 1.4rem;
    margin-right: 2rem;
    width: 80%;
  }
`;

export const AmenitiesBar = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  border: 2px solid #56A913;
  border-radius: 10px;
  width: 99%;
  height: 5rem;
  margin: 0.5rem;
  padding: 1rem 10rem;
  font-size: 1.5rem;

  //tab-port
  @media only screen and (max-width: 900px) {
    width: 98%;
    margin: 0 1%;
    padding: 1rem 5rem;
  }

   //phone
   @media only screen and (max-width: 600px) {
    width: 98%;
    margin: 0 1%;
    padding: 1rem 0;
  }
`;

export const AmenitiesIcon = styled.span`
  color: #9e9e9e;
  /* width: 2em;
  height: 2em; */
  font-size: 2rem;
`;
