import React from 'react'
import Discount from '../../components/discount/Discount'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import Acceptance from '../../components/includes/acceptance/Acceptance'
import Jumbotron from '../../components/includes/jumbotron/Jumbotron'
import Participant from '../../components/includes/participant/Participant'
import Programs from '../../components/includes/programs/Programs'
import ScrollToTop from '../../components/includes/scroll-to-top/ScrollToTop'
import Subscribe from '../../components/subscribe/Subscribe'

const Services = () => {
  return (
    <div>
        <Discount />
        <Header />
        <Jumbotron title={"Enhancement Services"} subhead={"We provide services to both adults and minors."}/>
        <ScrollToTop />
        <Acceptance />
        <Participant />
        <Programs />
        <Subscribe />
        <Footer />
    </div>
  )
}

export default Services