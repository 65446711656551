import React from "react";
import { IoMdQuote } from "react-icons/io";
import { MdLocalHospital} from "react-icons/md";
import {
  Review,
  ReviewHead,
  ReviewParagraph,
  ReviewQuote,
  ReviewSubHead,
  ReviewUser,
  ReviewContainer,
  UserCertificate,
  UserCertificateComposite,
  UserCertificateComposition,
  UserCertificateDiv,
  UserCertificateImg1,
  UserCertificateImg2,
  UserCertificateImg3,
  UserCertificatelogo,
  UserCertificateTextDiv,
  UserCertificateTextPara,
  UserCertificateTextSpan,
  UserCertificateQuote,
  UserCertificateSub,
  UserCertificateText,
  UserRev,
  UserRevButton,
  UserRevDiv,
  UserRevIcon,
  UserReview,
  UserRevLine,
  UserRevLink,
  UserRevQuote,
  UserRevText,
} from "./reviews.styles";

// &::before {
//     content: "\201C";
//     position: absolute;
//     top: -2.75rem;
//     left: -1rem;
//     line-height: 1;
//     font-size: 20rem;
//     color: var(--color-grey-light-2);
//     font-family: sans-serif;
//     z-index: 1;
// }

function Reviews() {
  return (
    <ReviewContainer>
      <UserCertificate>
        <UserCertificateQuote>
          <UserCertificatelogo url={"https://res.cloudinary.com/dftu6mjmt/image/upload/v1678349662/Brightlife/certify1_p0w9fc.jpg"} alt="Certification Logo" />
          <UserCertificateDiv></UserCertificateDiv>
          <UserCertificateText>
            Our Health Promotion Support and Healthy Lifestyle Development.
          </UserCertificateText>
        </UserCertificateQuote>
        <UserCertificateSub>
          <UserCertificateTextDiv>
            <UserCertificateTextPara>
              We are here to guide you every step of the way throughout your
              mental health journey. <br /> Thus our rehabilitation programs are
              designed to make life better and foster well-being.
            </UserCertificateTextPara>
            <UserCertificateTextSpan>
              Don't hesitate to enlist our help. Reach out to our professionals
              today!
            </UserCertificateTextSpan>
          </UserCertificateTextDiv>
          <UserCertificateComposite className="col-1-of-2">
            <UserCertificateComposition className="composition">
              <UserCertificateImg1
                src="https://res.cloudinary.com/dftu6mjmt/image/upload/v1678356615/Brightlife/Excercise_gnziyo.jpg"
                alt="Excercise"
                className="composition__photo composition__photo--p1"
              />
              <UserCertificateImg2
                src="https://res.cloudinary.com/dftu6mjmt/image/upload/v1678349849/Brightlife/Medication2_eu36ef.jpg"
                alt="Medication"
                className="composition__photo composition__photo--p2"
              />
              <UserCertificateImg3
                src="https://res.cloudinary.com/dftu6mjmt/image/upload/v1678349802/Brightlife/vac_o0rjr2.jpg"
                alt="Vacation"
                className="composition__photo composition__photo--p3"
              />
            </UserCertificateComposition>
          </UserCertificateComposite>
        </UserCertificateSub>
      </UserCertificate>

      <UserRev>
        <UserRevQuote>
          <UserRevDiv>1</UserRevDiv>
          <UserRevLine></UserRevLine>
          <UserRevText>
            Through best practices and evidence-based interventions, we
            emphasize Self-management and physical health conditions.
          </UserRevText>
          <UserRevIcon>
            <MdLocalHospital />
          </UserRevIcon>
          <UserRevButton>
            <UserRevLink href="/services">
              See All Practices
            </UserRevLink>
          </UserRevButton>
        </UserRevQuote>

        <UserReview className="user--review">
          <Review className="review">
            <ReviewQuote>
              <IoMdQuote />
            </ReviewQuote>
            <ReviewHead className="review--content">Our Commitment</ReviewHead>
            <ReviewSubHead className="review--content">
              Mission Statement
            </ReviewSubHead>
            <ReviewUser className="review--user">
              <ReviewParagraph>
                To render quality and comprehensive mental health care to those
                who are in need of pyschiatric treatment. We are poised at
                helping clients attain the needs of the individuals under our
                care by helping them reach their maximum capacity. Thus,
                allowing them to reach their goals and function independently in
                the community.
              </ReviewParagraph>
            </ReviewUser>
          </Review>
        </UserReview>

        {/* <UserReview className="user--review">
            {reviewItems.map((item) => (
              <Review className="review" key={item.id}>
                <ReviewQuote>
                  <FormatQuoteRounded />
                </ReviewQuote>
                <ReviewText className="review--content">{item.review}</ReviewText>
                <ReviewUser className="review--user">
                  <ReviewPhoto src={item.img} className="review--user__img" alt="User 1" />
                  <ReviewUserBox>
                    <ReviewUserName className="review--user__name">{item.name}</ReviewUserName>
                    <ReviewUserDate className="review--user__date">{item.date}</ReviewUserDate>
                  </ReviewUserBox>
                  <ReviewRating className="review--user__rating">{item.rating}</ReviewRating>
                </ReviewUser>
              </Review>
            ))}
          </UserReview> */}
      </UserRev>
    </ReviewContainer>
  );
}

export default Reviews;
