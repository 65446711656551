import React from "react";
import { serviceItems } from "../../../assets/data/data";
import styles from "./boxTop.module.scss";

const BoxTop = () => {
  return (
    <div className={styles["Featurebox--top"]}>
      {serviceItems.map((el, i) => {
        const { id, title, imgId, titleId } = el;
        return (
          <div className={styles["Featurebox--topp"]} key={`${i}${id}`}>
            <div className={styles["Featurebox--top__img"]}>
              <div
                className={styles["Featurebox--top__span"]}
                id={styles[imgId]}
              >
                &nbsp;
              </div>
            </div>
            <div className={styles["Featurebox--top__div"]}>
              <h4 className={styles["Featurebox--top__head"]}>
                <span
                  className={styles["Featurebox--top__title"]}
                  id={styles[titleId]}
                >
                  {title}
                </span>
              </h4>
            </div>
            <div className={styles["Featurebox--top__button"]}>
              <a className={styles["Featurebox--top__link"]} href="/contact-us">
                Request Service
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BoxTop;
