import styled from "styled-components";
import Image from "../../common/image/Image";

export const Container = styled.div`
  height: 50px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.2rem;
  font-size: 14px;
  font-weight: 500;
  border: none;
`;

export const Discbar = styled.div`
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  height: 40px;
  width: 100%;
`;

export const Discfollow = styled.span`
  font-size: 1rem;
  padding-left: 2.5rem;
  color: #000;
  width: 20%;

    //tab-land
    @media only screen and (max-width: 900px) {
    padding-left: .5rem;
    width: 20%;
  }

      //phone
      @media only screen and (max-width: 600px) {
    width: 50%;
  }
`;

export const Disclist = styled.div`
  display: flex;
  width: 30%;
  margin-right: 45rem;

      //tab-land
    @media only screen and (max-width: 900px) {
    padding-left: .5rem;
    width: 60%;
    margin-right: 10rem;
  }

        //phone
       @media only screen and (max-width: 600px) {
    width: 45%;
  }
`;

export const Discicon = styled.svg`
  color: #fff;
  background-color: #71d321;
  border-radius: 50%;
  width: 1.3rem;
  height: 1.3rem;
  cursor: pointer;
  margin: 0.2rem;
  padding: 0.2rem;
`;

export const Disclink = styled.a`
  text-decoration: none;
  color: #fff;
`;

export const Discnav = styled.div`
  display: flex;
  align-items: center;
  // flex: 1;
  width: 70%;
  /* margin-left: 9rem; */

  //tab-land
  @media only screen and (max-width: 1200px) {
    display: none;
    width: 0;
  }

`;

export const DiscnavIconbox = styled.div`
  position: relative;
  padding: 0 1.5rem;
  cursor: pointer;
  height: 100%;
  width: 25%;
  display: flex;
  align-items: center;
`;

export const DiscnavIcon = styled.svg`
  height: 1.5rem;
  width: 1.5rem;
  font-size: 20px;
  color: #000;
`;

export const DiscnavNotify = styled.span`
  font-size: 0.6rem;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: #71d321;
  color: #fff;
  position: absolute;
  top: 0.5rem;
  right: 0.7rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;


export const DiscncertImg = styled(Image)`
  height: 50px;
  width: 50px;
`;

export const DiscnavImg = styled(Image)`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  margin-top: 0.3rem;
  margin-right: 1rem;
`;

export const DiscnavUser = styled.div`
  padding: 0 2rem;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const DiscnavUserspan = styled.span`
  color: #000;
`;