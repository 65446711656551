import styled from "styled-components";
import Image from "../../common/image/Image";

export const ReviewContainer = styled.div`
  height: 100%;
  background-image: linear-gradient(
      to right bottom,
      rgba(250, 249, 249, 0.8),
      rgba(236, 234, 235, 0.8)
    ),
    url(../img/item-9.jpeg);
  background-attachment: fixed;
  background-size: cover;
  padding: 1rem 0 0 0;
  align-items: center;
  border-bottom: 1px solid #f4f2f2;
  overflow-x: hidden;
`;

export const UserCertificate = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5rem 0 10rem 0;
`;

export const UserCertificateQuote = styled.div`
  display: flex;
  padding-left: 2rem;
  margin: 5rem;

  //tab-land
  @media only screen and (max-width: 1200px) {
    margin: 5rem auto;
  }

  //tab-port
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-left: 0;
    width: 100%;
    margin: 5rem auto 0 auto;
  }
`;

export const UserCertificateDiv = styled.div`
  height: 15rem;
  width: 0.1rem;
  margin-right: 2rem;
  margin-left: 2rem;
  border: 1px solid gold;

  //tab-port
  @media only screen and (max-width: 900px) {
    height: auto;
    width: 50%;
    margin: 3rem 25%;
  }
`;

export const UserCertificateText = styled.p`
  font-size: 2.5rem;
  width: 60%;
  color: #777;
  /* font-family: ; */

  //tab-port
  @media only screen and (max-width: 900px) {
    font-size: 2rem;
  }

  //phone
  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

export const UserCertificatelogo = styled(Image)`
  width: 15rem;
  height: 15rem;
`;

export const UserCertificateSub = styled.div`
  display: flex;
  padding: 2rem 5rem;

  //tab-land
  @media only screen and (max-width: 1200px) {
    padding: 0 2rem;
  }

  //tab-port
  @media only screen and (max-width: 900px) {
    height: 100%;
    flex-direction: column;
    padding: 0 auto;
    align-items: center;
    text-align: center;
  }
`;

export const UserCertificateTextDiv = styled.p`
  font-size: 24px;
  font-weight: 700;
  width: 45%;
  margin-top: 5rem;

  //tab-land
  @media only screen and (max-width: 1200px) {
    font-size: 22px;
  }

  //tab-port
  @media only screen and (max-width: 900px) {
    font-size: 20px;
    width: 100%;
    padding: 0 10%;
  }
`;

export const UserCertificateTextPara = styled.p`
  margin-bottom: 3rem;
  color: #373737;

  //tab-land
  @media only screen and (max-width: 1200px) {
    margin-bottom: 1rem;
  }

  //tab-port
  @media only screen and (max-width: 900px) {
    width: 95%;
  }
`;

export const UserCertificateTextSpan = styled.span`
  color: #ff0844;

  //tab-port
  @media only screen and (max-width: 900px) {
    width: 70%;
  }
`;

export const UserCertificateComposite = styled.div`
  //   position: relative;
  width: 55%;

  //tab-port
  @media only screen and (max-width: 900px) {
    width: 100%;
    margin-bottom: 15rem;
  }
`;

export const UserCertificateComposition = styled.div``;

export const UserCertificateImg1 = styled.img`
  width: 35%;
  box-shadow: 0 1.5rem 4rem rgba(#000, 0.4);
  border-radius: 2px;
  position: absolute;
  z-index: 10;
  transition: all 0.2s;
  outline-offset: 2rem;
  left: 15%;
  top: 5rem;

  //phone
  @media only screen and (max-width: 600px) {
    width: 45%;
  }
`;
export const UserCertificateImg2 = styled.img`
  width: 35%;
  box-shadow: 0 1.5rem 4rem rgba(#000, 0.4);
  border-radius: 2px;
  position: absolute;
  z-index: 10;
  transition: all 0.2s;
  outline-offset: 2rem;
  right: 15%;
  top: 7rem;

  //phone
  @media only screen and (max-width: 600px) {
    width: 45%;
    right: 8%;
  }
`;
export const UserCertificateImg3 = styled.img`
  width: 35%;
  box-shadow: 0 1.5rem 4rem rgba(#000, 0.4);
  border-radius: 2px;
  position: absolute;
  z-index: 10;
  transition: all 0.2s;
  outline-offset: 2rem;
  left: 30%;
  top: 14rem;

  //tab-land
  @media only screen and (max-width: 1200px) {
    top: 12rem;
  }

  //tab-port
  @media only screen and (max-width: 900px) {
    top: 10rem;
  }

  //phone
  @media only screen and (max-width: 600px) {
    width: 45%;
  }
`;

export const UserRev = styled.div`
  background-color: #faf9f9;
  border-bottom: 1px solid #f4f2f2;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 0 0 0;
`;

export const UserReview = styled.figure`
  /* width: 100%;
  color: #777;
  */
  width: 85%;
  margin: 0 auto;
  box-shadow: 0 3rem 6rem rgba(#000, 0.1);
  background-color: rgba(#fff, 0.6);
  border-radius: 3px;
  padding: 0 6rem;
  transform: skewX(-12deg);

  //tab-port
  @media only screen and (max-width: 900px) {
    width: 95%;
    padding: 0 4rem;
  }

  //phone
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 0 2rem;
  }
`;

export const Review = styled.figure`
  box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.09);
  width: 100%;
  height: 18rem;
  margin: 1.5rem 0 1.5rem 0;
  position: relative;
  align-items: center;
  text-align: center;
  padding: 2rem 0 0 0;


  //tab-port
  @media only screen and (max-width: 900px) {
    height: 20rem;
  }

  //phone
  @media only screen and (max-width: 600px) {
    padding: 0;
    margin: 0;
    height: 22rem;
  }
`;

export const ReviewQuote = styled.span`
  position: absolute;
  top: -0.75rem;
  left: -25.5rem;
  line-height: 1;
  font-size: 150px;
  color: #f4f2f2;
  font-family: sans-serif;
  z-index: 1;

   //tab-land
   @media only screen and (max-width: 1200px) {
    left: -15.5rem;
    font-size: 140px;
  }

  //tab-port
  @media only screen and (max-width: 900px) {
    left: -10.5rem;
    font-size: 120px;
  }

  //phone
  @media only screen and (max-width: 600px) {
    font-size: 100px;
  }
`;

export const ReviewHead = styled.h4`
  font-weight: 500;
  font-size: 25px;
  color: #71d321;

      //tab-port
    @media only screen and (max-width: 900px) {
      font-size: 23px;
  }
`;

export const ReviewSubHead = styled.h1`
  font-weight: 700;
  font-size: 35px;
  color: gold;

    //tab-port
    @media only screen and (max-width: 900px) {
      font-size: 25px;
  }
`;

export const ReviewParagraph = styled.blockquote`
  font-size: 16px;
  transform: skewX(12deg);
  /* width: 80%; */

  @include respond(phone) {
    transform: skewX(0);
  }

   //phone
   @media only screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

export const ReviewUser = styled.div`
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 0 20%;

  //tab-land
  @media only screen and (max-width: 1200px) {
    padding: 0 10%;
  }

  //tab-port
  @media only screen and (max-width: 900px) {
    padding: 0 5%;
  }
`;

export const ReviewUserBox = styled.div`
  margin-right: auto;
`;

export const ReviewUserName = styled.p`
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0.4rem;
`;

export const ReviewUserDate = styled.p`
  font-size: 0.9rem;
  color: #999;
`;

export const ReviewRating = styled.div`
  color: #bf9000;
  font-size: 1.2rem;
  font-weight: 400;
`;

export const UserRevQuote = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding: 5rem;
  align-items: center;
`;

export const UserRevDiv = styled.h1`
  font-size: 3rem;
  text-align: center;
`;

export const UserRevLine = styled.span`
  width: 60%;
  border: 1px solid #000;
  margin: 3rem 0;
`;

export const UserRevText = styled.p`
  width: 70%;
  font-size: 1.6rem;
  /* line-spacing: 1.5rem; */
  text-align: center;

  //tab-port
  @media only screen and (max-width: 900px) {
    font-size: 1.2rem;
    width: 100%;
  }

  //phone
  @media only screen and (max-width: 600px) {
    font-size: 1rem;
  }
`;

export const UserRevButton = styled.button`
  width: 12rem;
  height: 3rem;
  border: 2px solid #56a913;
  border-radius: 10rem;
  background-color: #fff;
  margin-left: 1rem;
  cursor: pointer;
`;

export const UserRevLink = styled.a`
  text-decoration: none;
  color: #000;
`;

export const UserRevIcon = styled.span`
  color: #ff0844;
  margin: 2rem 0 0 0;
  font-size: 55px;
`;
