import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/Home";
import ContactUs from "./pages/contact-us/ContactUs";
import Services from "./pages/services/Services";
import AboutUs from "./pages/about-us/AboutUs";

function App() {
  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about-us" element={<AboutUs />} />
        </Routes>
    </div>
  );
}

export default App;
