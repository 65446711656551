import React from "react";
// import { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { IoMdFitness } from "react-icons/io";
import { TbEmergencyBed} from "react-icons/tb";
import { MdOutdoorGrill, MdMedicalServices, MdDeck} from "react-icons/md";
import { GiSecurityGate} from "react-icons/gi";
import { exploreItems } from "../../assets/data/data";
import {
  Amenities,
  AmenitiesBar,
  AmenitiesHead,
  AmenitiesIcon,
  Container,
  Explor,
  ExploreBranch,
  ExploreButton,
  ExploreDiv,
  ExploreGroup,
  ExploreHead,
  ExploreImg,
  ExploreLink,
  ExploreSub,
  ExploreText,
  ExploreTitle,
  TextPara,
} from "./explore.styles";

const Explore = () => {
  // const [slideIndex, setSlideIndex] = useState(0);
  // const handleClick = (direction) => {
  //   if (direction === "left") {
  //     setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 2);
  //   } else {
  //     setSlideIndex(slideIndex < 2 ? slideIndex + 1 : 0);
  //   }
  // };

  return (
    <Container>
      <Explor>
        <ExploreHead>
          We also promote daily independent living skills. These skills include
          but are not limited to:
        </ExploreHead>
        <ExploreDiv>
          {/* <ExploreArrow direction="left" onClick={() => handleClick("left")}>
        <ArrowLeftOutlined />
      </ExploreArrow> */}
          {/* <ExploreGroup slideIndex={slideIndex}> */}
          <ScrollAnimation animateIn="fadeInRight" duration="5s">
            <ExploreGroup>
              {exploreItems.map((item) => (
                <ExploreBranch key={item.id} className="Explore--branch">
                  <ExploreImg
                    className="Explore--img"
                    src={item.img}
                    alt={item.altName}
                  />
                  <ExploreTitle>
                    <ExploreSub>{item.title}</ExploreSub>
                  </ExploreTitle>
                </ExploreBranch>
              ))}
            </ExploreGroup>
          </ScrollAnimation>
          {/* <ExploreArrow direction="right" onClick={() => handleClick("right")}>
        <ArrowRightOutlined />
      </ExploreArrow> */}
        </ExploreDiv>
        <ExploreButton>
          <ExploreLink href="/services">Get Skillsets</ExploreLink>
        </ExploreButton>

        <ExploreText>
          <TextPara>
            Our community support and integration program is focused on helping
            consumers with mental health services that will provide support and
            treatment to optimize the consumer's emotional, psychological and
            somatic well-being. We aim to be a resource for comprehensive
            treatment for individuals, children, youth and families, including
            those living with serious mental illness and other disorders.
          </TextPara>
        </ExploreText>

        <Amenities>
          <AmenitiesHead>
            Our facilities come fully equiped with the following as standards:
          </AmenitiesHead>
          <AmenitiesBar>
            <AmenitiesIcon className="Amenities--icon">
              <TbEmergencyBed />
            </AmenitiesIcon>
            <AmenitiesIcon className="Amenities--icon">
              <MdOutdoorGrill />
            </AmenitiesIcon>
            <AmenitiesIcon className="Amenities--icon">
              <IoMdFitness />
            </AmenitiesIcon>
            <AmenitiesIcon className="Amenities--icon">
              <MdMedicalServices />
            </AmenitiesIcon>
            <AmenitiesIcon className="Amenities--icon">
              <MdDeck />
            </AmenitiesIcon>
            <AmenitiesIcon className="Amenities--icon">
              <GiSecurityGate />
            </AmenitiesIcon>
          </AmenitiesBar>
        </Amenities>
      </Explor>
    </Container>
  );
};

export default Explore;
