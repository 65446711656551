import React from 'react'
import { participantItems } from '../../../assets/data/data';
import styles from './participant.module.scss'

const Participant = () => {
    return (
        <div className={styles['participant--container']} id="idwhoweserve">
            <div className={styles['participant--jumbotron']}>
                <div className={styles['participant--top']}>
                    <div>
                        <h5 className={styles['participant--top__head']}>Who we serve</h5>
                    </div>
                    <div className={styles['participant--top__subhead-div']}>
                        <h3 className={styles['participant--top__subhead']}>We work with participants with:</h3>
                    </div>
                </div>
                <div className={styles['participant--bottom']}>
                    {participantItems.map((el, i) => {
                        const { id, title, icon } = el;
                        return (
                            <div className={styles["participant--btm"]} key={`${i}${id}`}>
                                <div className={styles["participant--btm__icon"]}>
                                    <span className={styles["participant--btm__span"]}>{icon}</span>
                                </div>
                                <div className={styles["participant--btm__text"]}>
                                    <div>
                                        <h1 className={styles['participant--btm__title']}>{title}</h1>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}


export default Participant