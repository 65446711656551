import React from "react";
import styled from "styled-components";
import styles from './aboutUsJumbotron.module.scss'

const JumbotronContainer = styled.div`
  /* height: 85vh;
    background-image: linear-gradient(
        to left bottom,
        rgba(207, 232, 255, 0.8),
    rgba(1, 126, 246, 0.8)),
    url(../img/counsel4.jpg);
    background-size: cover;
    background-position: top;
    position: relative;

    

    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100vh, 0 100%);
         clip-path: polygon(0 0, 100% 0, 100% 100vh, 0 100%); 
         height: 85vh; 
    } */

`;

// const JumbotronImage = styled(Image)`
//   width: 100%;
// `;

const JumbotronText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const JumbotronHead = styled.span`
  display: block;
  font-size: 6rem;
  font-weight: 600;
  letter-spacing: 2rem;
  color: #b09601;

  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;


  //tab-land
  @media only screen and (max-width: 1200px) {
    font-size: 4rem;
  }

  //tab-port
  @media only screen and (max-width: 900px) {
    font-size: 3rem;
    letter-spacing: 1rem;
  }

  //phone
  @media only screen and (max-width: 600px) {
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: .8rem;
  }
`;

const JumbotronSubhead = styled.span`
  display: block;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1.75px;
  animation: moveInRight 1s ease-out;
  color: #000;
`;

const JumbotronLink = styled.a`
  text-decoration: none;
  color: #000;
`;

const JumbotronButton = styled.div`
  width: 12rem;
  height: 3rem;
  border: 2px solid #56a913;
  border-radius: 10rem;
  background-color: #fff;
  margin-top: 3rem;
  margin-left: 45%;
  cursor: pointer;
  padding-top: 0.5rem;


  //tab-land
  @media only screen and (max-width: 1200px) {
    margin-left: 40%;
  }

   //tab-port
   @media only screen and (max-width: 900px) {
    margin-left: 35%;
  }

    //phone
    @media only screen and (max-width: 600px) {
      margin-left: 30%;
  }
`;

const AboutJumbotron = ({ title, subhead }) => {
  return (
    <JumbotronContainer className={styles['jumbotron--container']} >
      {/* <JumbotronImage url={community} alt={"jumbotron image"}/> */}
      <JumbotronText>
        <JumbotronHead>{title}</JumbotronHead>
        <JumbotronSubhead>{subhead}</JumbotronSubhead>
        <JumbotronButton>
          <JumbotronLink href="/contact-us" class="jumbotron--link">
            Receive A Solution
          </JumbotronLink>
        </JumbotronButton>
      </JumbotronText>
    </JumbotronContainer>
  );
};

export default AboutJumbotron;
