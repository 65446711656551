import styles from "./programs.module.scss";
import { miniprogramsItems, maxprogramsItems } from "../../../assets/data/data";
import { BiPlus } from "react-icons/bi";

const Programs = () => {
//   const [plan, setPlan] = useState(0);

//   const handleYearlyPlan = () => {
//     setPlan(1);
//   };

//   const handleMonthlyPlan = () => {
//     setPlan(0);
//   };

  return (
    <div className={styles["programs--container"]} id="idpricing">
      <div className={styles["programs--top"]}>
        <div className={styles["programs--top__heading"]}>
          <h3 className={styles["programs--top__head"]}>
            Psychiatric Rehabilitation Program (PRP)
          </h3>
          <div className={styles["programs--top__subhead-div"]}>
            <h5 className={styles["programs--top__subhead"]}>
              This program icludes the followig services:
            </h5>
          </div>
        </div>
        {/* <div className={styles["programs--top__btn"]}>
          <button
            className={styles["programs--top__btn-1"]}
            id={plan === 0 ? styles["blue"] : styles["teal"]}
            onClick={handleMonthlyPlan}
          >
            Monthly
          </button>
          <button
            className={styles["programs--top__btn-2"]}
            id={plan === 0 ? styles["teal"] : styles["blue"]}
            onClick={handleYearlyPlan}
          >
            Yearly
          </button>
        </div> */}
      </div>

      <div className={styles["programs--bottom"]}>
        <div
          className={styles["programs--bottoms"]}
          id={styles["bottom__left"]}
        >
          <div className={styles["programs--bottom__left-top"]}>
            <div className={styles["programs--bottom__heading"]}>
              <h4 className={styles["programs--bottom__user"]}>
                Over 100 users
              </h4>
            </div>
            <div className={styles["programs--bottom__programs"]}>
              {/* {(plan === 0 && (
                <h3 className={styles["programs--bottom__plan"]}>
                  $5/ user / month
                </h3>
              )) ||
                (plan === 1 && (
                  <h3 className={styles["programs--bottom__plan"]}>
                    $50/ user / yearly
                  </h3>
                ))} */}
              <h3 className={styles["programs--bottom__plan"]}>Ask for help</h3>
            </div>
            <div className={styles["programs--bottom__line"]}></div>
          </div>
          <div className={styles["programs--bottom__left-center"]}>
            {maxprogramsItems.map((el, i) => {
              const { id, title } = el;
              return (
                <div className={styles["miniprograms--btm"]} key={`${i}${id}`}>
                  <div className={styles["miniprograms--btm__plus"]}>
                    <span className={styles["miniprograms--btm__plus-left"]}>
                      <BiPlus />
                    </span>
                  </div>
                  <div className={styles["miniprograms--btm__feat"]}>
                    <h4 className={styles["miniprograms--btm__feature"]}>
                      {title}
                    </h4>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={styles["programs--bottoms"]}
          id={styles["bottom__right"]}
        >
          <div className={styles["programs--bottom__right-top"]}>
            <div className={styles["programs--bottom__heading"]}>
              {/* <h3 className={styles["programs--bottom__head"]}>Enterprise</h3> */}
              <h4 className={styles["programs--bottom__user"]}> 100+ users</h4>
            </div>
            <div className={styles["programs--bottom__programs"]}>
              <h3 className={styles["programs--bottom__plan"]}>Contact us</h3>
            </div>
            <div className={styles["programs--bottom__line"]}></div>
          </div>
          <div className={styles["programs--bottom__right-center"]}>
            { miniprogramsItems.map((el, i) => {
              const { id, title } = el;
              return (
                <div className={styles["miniprograms--btm"]} key={`${i}${id}`}>
                  <div className={styles["miniprograms--btm__plus"]}>
                    <span className={styles["miniprograms--btm__plus-right"]}>
                      <BiPlus />
                    </span>
                  </div>
                  <div className={styles["miniprograms--btm__feat"]}>
                    <h4 className={styles["miniprograms--btm__feature"]}>
                      {title}
                    </h4>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Programs;
