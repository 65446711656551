import styled from "styled-components";
import Image from "../../common/image/Image";

export const Container = styled.div`
  width: 100vw;
`;

export const Navbar = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height: 80px;

   //tab-port
   @media only screen and (max-width: 900px) {
     justify-content: space-between;
  }
`;

export const HamburgerButton = styled.button`
  display: none;

    //tab-port
  @media only screen and (max-width: 900px) {
  display: flex;
  padding-left: 60%;
  font-size: 30px;
  cursor: pointer;
  border: none;
  background: none;
  color: inherit;
  z-index: 21;
  }
`;



export const NavbarImg = styled.div`
  display: flex;
  width: 70%;
  align-items: center;
`;

export const NavbarLogo = styled(Image)`
  width: 60px;
  height: 60px;
  margin: 0 10px 5px 20px;

   //tab-port
   @media only screen and (max-width: 900px) {
    margin-left: 2rem;
  }
`;

export const Name = styled.h3`
  font-family: El Messiri;
  font-size: 2rem;
  font-weight: 700;
  color: #387505;

    //tab-port
    @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export const DesktopList = styled.div`
  display: flex;
  width: 30%;

   //tab-land
   @media only screen and (max-width: 1200px) {
    width: 40%;
    padding-right: 2rem;
  }

   //tab-port
   @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export const MobileList = styled.div`
  display: none;

  //tab-port
  @media only screen and (max-width: 900px) {
    background-color: #f5f5f5;
    display: flex;
      position: absolute;
      padding: 2rem 3rem;
      width: 100%;
      z-index: 20;
      flex-direction: column;
      top: 70px;
      left: 0px;
      gap: 30px;
      animation: moveInTop 0.6s ease-in-out;
  }
`;

export const UnorderedList = styled.a`
  color: #515151;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
`;

export const Linebreak = styled.ul``;
