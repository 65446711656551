import React from "react";
import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";
import { IoIosPeople} from "react-icons/io";
import { FaHandHoldingMedical} from "react-icons/fa";
import { MdDuo, MdDirectionsBike} from "react-icons/md";
import {
  Container,
  Feature,
  FeatureBox,
  FeatureboxHeading,
  FeatureboxHeadingg,
  FeatureboxHeadinggs,
  FeatureboxHeadings,
  FeatureboxIcon,
  FeatureBoxs,
  Featureboxdiv,
  Featureboxfigure,
  FeatureboxHead,
  FeatureboxHeadmin,
  FeatureboxImg,
  FeatureboxMax,
  FeatureboxMin,
  FeatureboxPara,
  ListItem,
  Row,
} from "./featurebox.styles";
import BoxTop from "./box-top/BoxTop";

function Featurebox() {
  return (
    <Container>
      {/* <div className={'card__side card__side--front">
        <div className="card__picture card__picture--3">&nbsp;</div>
        <h4 className="card__heading">
          <span className="card__heading-span card__heading-span--3">
            The Marriage Counselor
          </span>
        </h4>
      </div> */}
        <BoxTop />
      <Featureboxdiv>
        <FeatureboxMin className={'Featurebox__main--pic'}>
          <Featureboxfigure>
            <FeatureboxImg src="https://res.cloudinary.com/dftu6mjmt/image/upload/v1704701619/Brightlife/WhatsApp_Image_2024-01-08_at_00.31.17_czw2mj.jpg" alt="User photo" />
          </Featureboxfigure>
        </FeatureboxMin>
        <FeatureboxMax className={'Featurebox__main--box'}>
          <FeatureboxHeadmin>Hello,</FeatureboxHeadmin>
          <FeatureboxHead>Keeping Your Health in Mind</FeatureboxHead>
          <ScrollAnimation animateIn="fadeIn">
            <FeatureboxPara>
              “Right now, all of our initial visits are done in person while
              follow up visits could be provided via telehealth. Please call us
              to schedule an appointment or a phone call with your therapist. If
              you are having an emergency, please call 911..."
            </FeatureboxPara>
          </ScrollAnimation>
        </FeatureboxMax>
        {/* <Circlerad className={'animate__animated animate__bounce">
            <CircleNum>6</CircleNum>
            <CircleText>Service Apartments</CircleText>
          </Circlerad>
          <Circlerads className={'animate__animated animate__wobble">
            <CircleNums>50</CircleNums>
            <CircleText>and More Delicacies</CircleText>
          </Circlerads>
          <Circlerad className={'animate__animated animate__bounce">
            <CircleNum>10</CircleNum>
            <CircleText>Hotel Rooms</CircleText>
          </Circlerad>
          <Circlerads className={'animate__animated animate__wobble">
            <CircleNums>100%</CircleNums>
            <CircleText>Safety and Privacy</CircleText>
          </Circlerads> */}
      </Featureboxdiv>

      <Row>
      <Feature className={'feature'}>
          <FeatureBox>
            <FeatureboxIcon>
              <MdDuo />
            </FeatureboxIcon>
            <FeatureboxHeading>OMHC</FeatureboxHeading>
            <FeatureboxHeadingg></FeatureboxHeadingg>
            <ListItem>
              Out-patient Mental Health Center, provide
              psychiatric evaluation, therapies like medication management recycle,
              cognitive behavioural, individual, couples, and family therapy.
            </ListItem>
          </FeatureBox>
        </Feature>

        <Feature className={'feature'}>
          <FeatureBoxs>
            <FeatureboxIcon>
              <IoIosPeople />
            </FeatureboxIcon>
            <FeatureboxHeadings>Community Support Programs</FeatureboxHeadings>
            <FeatureboxHeadinggs></FeatureboxHeadinggs>
            <ListItem>
              Our community support and integration program (PRP) is focused on
              helping consumers with mental health services.
            </ListItem>
          </FeatureBoxs>
        </Feature>

        <Feature className={'feature'}>
          <FeatureBox>
            <FeatureboxIcon>
              <FaHandHoldingMedical />
            </FeatureboxIcon>
            <FeatureboxHeading>Treatment Programs</FeatureboxHeading>
            <FeatureboxHeadingg></FeatureboxHeadingg>
            <ListItem>
              Our treatment programs provide culturally and linguistically
              appropriate services that include, but are not limited to
              individual, group, & family counselling & education on
              wellness, recovery & resiliency.
            </ListItem>
          </FeatureBox>
        </Feature>

        <Feature className={'feature'}>
          <FeatureBoxs>
            <FeatureboxIcon>
              <MdDirectionsBike />
            </FeatureboxIcon>
            <FeatureboxHeadings>Holistic Approach</FeatureboxHeadings>
            <FeatureboxHeadinggs></FeatureboxHeadinggs>
            <ListItem>
              We work with individuals using a holistic approach, through best
              practices and evidence-based interventions, we emphasize
              self-management of mental and physical health conditions.
            </ListItem>
          </FeatureBoxs>
        </Feature>
      </Row>
    </Container>
  );
}

export default Featurebox;
