import React, { useEffect, useState } from "react";
import Text, { Span } from "../../common/typography/index";
import Button, {
  BUTTON_TYPE_CLASSES,
} from "../../common/button/button.component";
import Image from "../../common/image/Image";
import { MainContainer } from "./main.styles";
import { sliderItems } from "../../assets/data/data";

const Main = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselLength = sliderItems.length;

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === carouselLength - 1 ? 0 : prevSlide + 1
      );
    }, 3000);

    return () => {
      clearInterval(slideInterval);
    };
  }, [carouselLength]);

  return (
    <MainContainer className={"main"}>
      <div className={"main__content"}>
        <div className={"main__content--text"}>
          <Text className={"main__content--text-1"}>
            BrightLife Enhancement Services. Holistic Approach to Health.
          </Text>
          <div className={"main__content--text-2"}>
            <Span>
              Feel free to contact us and we’ll get back to you shortly.
            </Span>
          </div>
        </div>
        <div className={"main__content--btn"}>
          <a href="/contact-us" className={"main__content--btn-link"}>
          <Button
            buttonType={BUTTON_TYPE_CLASSES.curved}
            className={"main__content--btn-1"}
          >
            Register Now
          </Button>
          </a>
        </div>
      </div>
      <div className={"main__img"}>
        <div className={"main__image"}>
          {sliderItems.map((item) => {
            const active = currentSlide === item.id;
            return (
              <div
                key={item.id}
                className={
                  active ? "main__imageSlideActive" : "main__imageSlide"
                }
              >
                  <Image
                    className={"main__image--img"}
                    url={item.img}
                    altName="main Image"
                  />
              </div>
            );
          })}
        </div>
      </div>
    </MainContainer>
  );
};

export default Main;
