import styled from "styled-components";

export const Container = styled.div`
  // height: 600px;
  width: 100%;
  padding: 5rem 0;
  height: 100%;
  background-image: linear-gradient(
      to right bottom,
      rgba(250, 249, 249, 0.8),
      rgba(236, 234, 235, 0.8)
    ),
    url(https://res.cloudinary.com/dftu6mjmt/image/upload/v1678349881/Brightlife/Ment_dtfeld.jpg);
  background-attachment: fixed;
  background-size: cover;

    /* //tab-land
    @media only screen and (max-width: 1200px) {
    padding: 5rem 0 0 5rem;
  } */
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  //big-desktop
  @media only screen and (max-width: 1800px) {
    /* For everything bigger than 768px */
  }

  //tab-land
  @media only screen and (max-width: 1200px) {
    display: grid;
    grid-template-columns: auto auto;
    place-items: center;
  }

  //phone
  @media only screen and (max-width: 600px) {
    display: grid;
    grid-template-columns: auto;
  }
`;

export const Feature = styled.div`
  width: 23%;

  //tab-land
  @media only screen and (max-width: 1200px) {
    width: 70%;
    margin-bottom: 2rem;
  }

  //tab-port
  @media only screen and (max-width: 900px) {
    width: 90%;
  }

  //phone
  @media only screen and (max-width: 600px) {
    width: 70%;
  }
`;

export const FeatureBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  height: 25rem;
  font-size: 1rem;
  padding: 2.5rem;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(#000, 0.15);
  transition: transform 0.3s;
  animation: moveInRight 5s ease-in-out;

  //tab-land
  @media only screen and (max-width: 1200px) {
    padding: 2rem;
  }

   //phone
   @media only screen and (max-width: 600px) {
    padding: 2.5rem;
    height: 100%;
  }
`;

export const FeatureBoxs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #777;
  color: #fff;
  height: 25rem;
  font-size: 1rem;
  font-weight: 500;
  padding: 2.5rem;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(#000, 0.15);
  transition: transform 0.3s;
  animation: moveInRight 5s ease-in-out;

    //tab-land
    @media only screen and (max-width: 1200px) {
    padding: 2rem;
  }

  //phone
  @media only screen and (max-width: 600px) {
    padding: 2.5rem;
    height: 100%;
  }
`;

export const FeatureboxIcon = styled.span`
  height: 3rem;
  width: 3rem;
  font-size: 35px;
  margin-bottom: 0.5rem;
  display: inline-block;
  -webkit-background-clip: text;
  color: #71d321;
`;

export const FeatureboxHeading = styled.h3`
  color: #000;
  font-size: 1rem;
  margin: 0.5rem auto;
  text-align: center;
`;

export const FeatureboxHeadings = styled.h3`
  color: #fff;
  font-size: 1rem;
  margin: 0.5rem auto;
  text-align: center;
`;

export const FeatureboxHeadingg = styled.div`
  background-color: gold;
  width: 40%;
  height: 0.2rem;
  margin-bottom: 1rem;
  text-align: center;
`;

export const FeatureboxHeadinggs = styled.div`
  background-color: gold;
  width: 40%;
  height: 0.2rem;
  margin-bottom: 1rem;
  text-align: center;
`;

export const ListItem = styled.p`
  text-align: center;
`;

export const Featureboxdiv = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 5rem;

  //tab-port
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;

export const FeatureboxMin = styled.div`
  width: 50%;
  text-align: center;
  padding: 1rem;

  //tab-port
  @media only screen and (max-width: 900px) {
    width: 100%;
    padding: 0;
  }
`;

export const FeatureboxMax = styled.div`
  width: 50%;
  padding: 4rem 6rem;
  margin-top: 2rem;

  //tab-port
  @media only screen and (max-width: 900px) {
    width: 80%;
    padding: 0;
    margin-bottom: 4rem;
  }
`;

export const Featureboxfigure = styled.figure``;

export const FeatureboxImg = styled.img`
  width: 90%;
  height: 25rem;
  margin: 4rem 6rem;

  //tab-land
  @media only screen and (max-width: 1200px) {
    height: 22rem;
  }

  //tab-port
  @media only screen and (max-width: 900px) {
    width: 60%;
    height: 20rem;
    margin: 0;
    object-fit: contain;
  }

  //phone
  @media only screen and (max-width: 600px) {
    width: 70%;
    height: 15rem;
  }
`;

export const FeatureboxHead = styled.h2`
  color: #777;
  font-size: 2.5rem;
  font-weight: 800;

  //tab-land
  @media only screen and (max-width: 1200px) {
    font-size: 2rem;
  }

  //phone
  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

export const FeatureboxHeadmin = styled.h4`
  color: #000;
  font-size: 1rem;
  font-weight: 400;

  //tab-port
  @media only screen and (max-width: 900px) {
    text-align: left;
    margin-left: 20%;
  }

  //phone
  @media only screen and (max-width: 600px) {
    margin-left: 10%;
  }
`;

export const FeatureboxPara = styled.p`
  font-size: 1.5rem;
  color: #373737;
  text-align: left;
  margin-top: 2rem;

  //tab-land
  @media only screen and (max-width: 1200px) {
    font-size: 1.2rem;
    width: 100%;
  }

  //tab-port
  @media only screen and (max-width: 900px) {
    text-align: center;
    align-self: center;
    width: 80%;
    padding-left: 20%;
  }

  //phone
  @media only screen and (max-width: 600px) {
    width: 90%;
    padding-left: 10%;
  }
`;

export const Featurebox = styled.div``;
