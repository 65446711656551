import React from 'react'
import styles from './sliderArrows.module.scss';
import { RxCaretRight, RxCaretLeft } from "react-icons/rx";
import { coreItems } from '../../../../assets/data/data';


const SliderArrows = ({ handleClick, slideIndex }) => {
  return (
    // <div className={styles['container']}>
      <div className={styles["core--btm__arrows"]}>
        <div className={styles["core--btm__arrow"]} id={styles[slideIndex === 0 ? "core--arrows__light" : "core--arrows__dark"]} onClick={() => handleClick("left")}>
          <span className={styles["core--btm__arrows-span"]}><RxCaretLeft /></span>
        </div>
        <div className={styles["core--btm__arrow"]} id={styles[slideIndex === coreItems.length-1 ? "core--arrows__light" : "core--arrows__dark"]} onClick={() => handleClick("right")}>
          <span className={styles["core--btm__arrows-span"]}><RxCaretRight /></span>
        </div>
      </div>
    // </div>
  )
}

export default SliderArrows
