import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  height: 550px;
  display: flex;
  flex-direction: row;
  background-color: #f5f5f5;

  //tab-port
  @media only screen and (max-width: 900px) {
    display: block;
    position: relative;
    align-items: center;
    text-align: center;
    height: 100%;
  }

  .main__content {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 4rem auto 6rem 4.5rem;

    //tab-land
    @media only screen and (max-width: 1200px) {
      width: 45%;
    }

    //tab-port
    @media only screen and (max-width: 900px) {
      width: 90%;
      height: 100%;
      margin: 0 !important;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 9;
    }

    //tab-phone
    @media only screen and (max-width: 600px) {
      width: 100%;
      margin: 0 !important;
      justify-content: center;
      align-items: center;
    }
  }

  .main__content--text {
    width: 75%;
    height: 196.43px;

    //tab-land
    @media only screen and (max-width: 1200px) {
      width: 90%;
    }

    //tab-port
    @media only screen and (max-width: 900px) {
      width: 60%;
      /* padding: 13% auto; */
      margin-top: 2rem;
    }

    //phone
    @media only screen and (max-width: 600px) {
      width: 100%;
      padding: 5% 10% 10% auto;
      margin-top: 0;
    }
  }

  .main__content--text-1 {
    font-family: Mulish;
    font-weight: 800;
    font-size: 56px;
    line-height: 70.28px;

    //tab-land
    @media only screen and (max-width: 1200px) {
      font-size: 48px;
      line-height: 60.28px;
    }

    //tab-port
    @media only screen and (max-width: 900px) {
      line-height: 42.28px;
      text-align: center;
      color: #f5f5f5;
      font-size: 38px;
      margin-bottom: 2rem;
    }
  }


  .main__content--text-2 {
    font-family: Mulish;
    font-weight: 400;
    font-size: 20px;
    color: #565555;

     //tab-port
     @media only screen and (max-width: 900px) {
      font-size : 20px;
    }
  }

  .main__content--btn {
    //tab-port
    @media only screen and (max-width: 900px) {
      margin-left: 60%;
    }

      //phone
      @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  .main__content--btn-1 {
    width: 250px;
    font-size: 18px !important;

    //tab-port
    @media only screen and (max-width: 900px) {
      height: 55px !important;

    }
  }

  .main__content--btn-link{
    text-decoration: none;
  }

  .main__img {
    display: flex;
    width: 45%;

    //tab-land
    @media only screen and (max-width: 1200px) {
      width: 55%;
    }

    //tab-port
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
  }

  .main__image {
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  .main__image--img {
    width: 100% !important;
    height: 550px !important;
    object-fit: cover;
  }

  .main__imageSlide {
    background-size: cover;
    background-position: center;
    display: none;
    transition: opacity 0.5s ease-in-out;

    //tab-port
    @media only screen and (max-width: 900px) {
      height: 600px !important;
    }

    //phone
    @media only screen and (max-width: 600px) {
      height: 420px !important;
    }
  }

  .main__imageSlideActive {
    display: block;
    background-size: cover;
    background-position: center;
    transition: opacity 0.5s ease-in-out;
  }
`;
