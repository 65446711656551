import styled from "styled-components";
import Image from "../../../common/image/Image";

export const AcceptanceContainer = styled.div`
  width: 100%;
  padding: 5rem 5rem 0 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;


    //tab-land
    @media only screen and (max-width: 1200px) {
    padding: 5rem 3rem 0 3rem;
  }

   //tab-port
   @media only screen and (max-width: 900px) {
    flex-direction: column;
    padding: 5rem 3rem 0 3rem;
    text-align: center;
  }
`;

export const AcceptanceImage = styled.div`
    width: 40%;

    //tab-land
    @media only screen and (max-width: 1200px) {
    width: 50%;
  }

   //tab-port
   @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const AcceptanceImg = styled(Image)`
    height: 250px;
    width: 80%;
    object-fit: contain;


   //tab-port
   @media only screen and (max-width: 900px) {
    width: 60%;
    margin-bottom: 3rem;
  }

  //phone
  @media only screen and (max-width: 600px) {
    width: 80%;
  }
`;

export const AcceptanceText = styled.div`
    width: 60%;

      //tab-land
  @media only screen and (max-width: 1200px) {
    width: 50%;
  }

   //tab-port
   @media only screen and (max-width: 900px) {
    width: 100%;
  }

  //phone
  /* @media only screen and (max-width: 600px) {
    width: 70%;
  } */
`;

export const AcceptancePara = styled.p`
    color: #000;
    font-size: 20px;

    //tab-port
   @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const AcceptanceSpan = styled.span`
    color: #71d321;
    font-size: 24px;
    font-weight: 700;
`;
