import React from 'react'
import { useState } from 'react';
import { coreItems } from '../../../assets/data/data';
import Image from '../../../common/image/Image';
import styles from './core.module.scss';
import SliderArrows from './slider-arrow/SliderArrows';

const Core = () => {
    const [slideIndex, setSlideIndex] = useState(0);

    const handleClick = (direction) => {
        if (direction === "left") {
            setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 0);
        } else {
            setSlideIndex(slideIndex < coreItems.length-1 ? slideIndex + 1 : coreItems.length-1);
        }
    };

    const translate3D = () => {
        const percentagePerSlide = 100 / coreItems.length
        const percentage = -percentagePerSlide * slideIndex + '%'
        const translate = `translate3d(${percentage}, 0, 0)`
        return translate;
    };

    return (
        <div className={styles['core--container']} id="idcore">
            <div className={styles['core--top']}>
                <div className={styles['core--top__major']}>
                    <h1 className={styles['core--top__head']}> Core Components</h1>
                    <div className={styles['core--top__div']}></div>
                </div>
                <div className={styles['core--top__minor']}>
                    <p className={styles['core--top__para']}>BrightLife Enhancement Services' vision and 
                    philosophy revolve around six core components:</p>
                </div>
            </div>
            <div className={styles['core--bottom__container']}>
                <div style={{ width: coreItems.length * 100 + '%', transform: translate3D() }} className={styles['core--bottom']}>
                {coreItems.map((el, i) => {
                    const { id, title, url, coreId, feature } = el;
                    return (
                        <div style={{ width: 100 / coreItems.length + '%' }} className={styles["core--btm"]} key={`${i}${id}`}>
                            <div className={styles["core--btm__left"]}>
                                <div className={styles["core--btm__title"]}>
                                    <h3 className={styles["core--btm__tit"]}>{title}</h3>
                                </div>
                                {/* <div id={styles[id]} className={styles['core--btm__heading']}>
                                    <h1 className={styles['core--btm__head']}>{heading}</h1>
                                </div> */}
                                <div id={styles[coreId]} className={styles['core--btm__paragraph']}>
                                    <p className={styles['core--btm__para']}>{feature}</p>
                                </div>
                                <SliderArrows handleClick={handleClick} slideIndex={slideIndex} />
                            </div>
                            <div className={styles["core--btm__right"]}>
                                <Image className={styles["core--btm__right-img"]} url={url} altName="Core Item" />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>

        </div >
    )
}


export default Core