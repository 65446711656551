import styled from "styled-components";

export const BaseButton = styled.button`
  min-width: 50px;
  width: ${({ width }) => width || "250px"};
  height: ${({ height }) => height || "74.83px"};
  color: #000;
  background-color: ${({ bgcolor }) => bgcolor || "#71d321"};
  border: 1px solid #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding || "16px 24px"};
  gap: 16px;

  &:hover {
    border: none;
  }
`;

export const CurvedButton = styled(BaseButton)`
  border-radius: 8px;
`;
