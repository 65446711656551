import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100%;
  padding: 2rem 6rem 5rem 6rem;
  background-color: #faf9f9;

    //tab-port
    @media only screen and (max-width: 900px) {
      padding: 2rem;
  }
`;

export const FooterDiv = styled.div``;

export const FooterLine = styled.div`
  width: 100%;
  border: 0.5px solid #000;
  margin: 3rem 0;
`;

export const FooterLineDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

   //phone
   @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const FooterPermission = styled.div`
  width: 70%;

  
     //tab-land
     @media only screen and (max-width: 1200px) {
      width: 50%;
  }

  //tab-port
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const Footercopyright = styled.div`
  display: flex;
`;

export const Footcopyright = styled.svg`
padding-top: .2rem;
  width: 20px;
  height: 20px;
`;

export const FooterPermit = styled.p`
  font-size: 1rem;
`;

export const FooterpermitLinks = styled.ul`
  display: flex;
  font-size: 1rem;
  cursor: pointer;
  padding: 1rem 0;
  margin: 0 1rem;

    //tab-land
    @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const Permitlink = styled.a`
  text-decoration: none;
  font-size: 0.9rem;
  color: #777;
`;

export const PermitlinkList1 = styled.li`
  width: 48%;

  //tab-land
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const PermitlinkList2 = styled.li`
  width: 42%;

   //tab-land
   @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const PermitlinkList3 = styled.li`
  width: 40%;

   //tab-land
   @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const PermitlinkList4 = styled.li`
  width: 68%;

   //tab-land
   @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const PermitlinkList5 = styled.li`
  width: 18%;

   //tab-land
   @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const FooterLink = styled.div`
  display: flex;
  width: 30%;
  padding: 0 0 0 8rem;
  cursor: pointer;
  
  
     //tab-land
     @media only screen and (max-width: 1200px) {
      width: 50%;
  }

  //tab-port
  @media only screen and (max-width: 900px) {
    width: 100%;
    padding: 0%;
    justify-content: center;
  }
`;

export const FooterLinkDiv = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  margin-right: 1rem;
`;

export const FooterlinkIcon1 = styled.span`
  color: #4267b2;
  top: 25%;
  left: 25%;
  font-size: 30px;
  cursor: pointer;
`;

export const FooterlinkIcon2 = styled.span`
  color: #8a3ab9;
  top: 25%;
  left: 25%;
  font-size: 30px;
  cursor: pointer;
`;
export const FooterlinkIcon3 = styled.span`
  color: #0072b1;
  top: 25%;
  left: 25%;
  font-size: 30px;
  cursor: pointer;
`;
export const FooterlinkIcon4 = styled.span`
  color: #ff0000;
  top: 25%;
  left: 25%;
  font-size: 30px;
  cursor: pointer;
`;
export const FooterlinkIcon5 = styled.span`
  color: #1da1f2;
  top: 25%;
  left: 25%;
  font-size: 30px;
  cursor: pointer;
`;

export const FooterPermitDiv = styled.div`
  margin: 2rem 0 0 0;
  color: #777;
  font-size: 1rem;
`;

export const FooterPermitTrade = styled.div``;

export const FooterPermitProp = styled.div`
  margin: 1rem 0 0 0;
`;