import styled from "styled-components";

export const BookingDiv = styled.div`
   padding-bottom: 5rem;
`;

export const Container = styled.div`
  width: 100%;
  height: 80vh;
  background-color: #fff;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 5rem 0;

   //tab-port
   @media only screen and (max-width: 900px) {
    justify-content: center;
    align-items: center;
    height: 90rem;
   flex-direction: column;
   margin: 0;
  }
`;

export const BookContainer = styled.div`
position: relative;
  width: 35%;
  height: 95vh;
  background-color: #faf9f9;
  padding-top: 1.5rem;
  margin: 4rem 0 5rem 8rem;
  color: #fff;
  border-radius: 2%;

   //tab-port
   @media only screen and (max-width: 900px) {
    width: 50%;
    margin: 3rem auto;
  }

  //phone
  @media only screen and (max-width: 600px) {
    width: 80%;
    margin: 3rem auto;
  }
`;


export const BookContainerHead = styled.h5`
  color: #000;
  text-align: center;
  font-weight: 400;
  padding: .5rem;
`;

export const BookContainerInput = styled.div`
  display: flex;
`;

export const BookContainertext = styled.input`
padding: 1rem;
margin: 1rem;
border: .2px solid #fff;
`;

export const BookContainersubject = styled.input`
padding: 1rem;
margin: 1rem;
border: .2px solid #fff;
width: 93%;
`;

export const BookContainermessage = styled.textarea`
padding: 1rem;
margin: 1rem;
border: .2px solid #fff;
width: 93%;
height: 25%;
`;

export const BookContainerButton = styled.button`
 color: #fff;
 position: absolute;
 left: 13rem;
 top: 38rem;
min-width: 20%;
max-width: 40%;
height: 3rem;
border: 2px solid #71d321;
border-radius: 10px;
background-color: #71d321;
cursor: pointer;
font-size: 1.2rem;


   /* //tab-port
   @media only screen and (max-width: 900px) {
    left: 25%;
  }

  //phone
  @media only screen and (max-width: 600px) {
    left: 30%;
  } */
`;


export const ContactContainer = styled.div`
    width: 50%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    margin: 2rem 0 2rem 2rem;
    border-radius: 20px;

       //tab-port
   @media only screen and (max-width: 900px) {
    text-align: center;
    justify-content: center;
    margin: 2rem 0 5rem 0
  }
`;


export const ContactInfo = styled.div`
    margin-top: 5rem;
`;

export const ContactHeading = styled.h1`
    color: #777;
    font-weight: 500;
    margin-bottom: 2rem;

    //phone
  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

export const ContactAddress = styled.p`
    font-size: 1.3rem;
    margin-bottom: 2rem;

       //phone
  @media only screen and (max-width: 600px) {
    font-size: 18px;
  }
`;

export const ContactEmail = styled.p`
    font-size: 1rem;
`;

export const ContactTel = styled.p`
    font-size: 1rem;
    margin-bottom: 3rem;
`;

export const ContactSpan = styled.span`
    color: #56A913;
    font-weight: 900;
    font-family: El Messiri;
    font-size: 1.2rem;
`;

export const WorkInfo = styled.p`

`;

export const WorkTime = styled.p`

`;