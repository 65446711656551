import React, { useRef, useState, useEffect } from "react";
import { Container, DesktopList, HamburgerButton, Linebreak, MobileList, Name, Navbar, NavbarImg, NavbarLogo, UnorderedList } from "./header.styles";
import { RiMenu3Fill } from 'react-icons/ri';
import { RxCross2 } from "react-icons/rx";

function Header({ handleShow }) {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  const options = {
    // block: "center",
    behavior: "smooth"
  }

  const scrollToSection = (id) => {
    const section = document.querySelector(`#id${id}`)
    section.scrollIntoView(options);
  };

  useEffect(() => {
    const handler = (e) => {
      if (!ref.current?.contains(e.target)) {
        setShow(false);
      }
    };
    if (show) {
      setTimeout(() => {
        document.addEventListener('click', handler)
      }, 0)
    } else {
        document.removeEventListener('click', handler);
    };
    return () => {
      document.removeEventListener('click', handler);
    };
  }, [show]);

  return (
    <Container ref={ref}>
      <Navbar>
        <NavbarImg>
          <NavbarLogo url={"https://res.cloudinary.com/dftu6mjmt/image/upload/v1678349750/Brightlife/logo1_zfr2pe.jpg"} alt="Glory Suites' logo" />
          <Name>
            BrightLife Enhancement Services
          </Name>
        </NavbarImg>
        <HamburgerButton
            onClick={() => setShow((prev) => !prev)}
          >
            {show ? <RxCross2 /> : <RiMenu3Fill />}
        </HamburgerButton>
        <DesktopList>
          <UnorderedList href="/" className="unorderedList active" onClick={() => scrollToSection("home")}>Home</UnorderedList>
          <UnorderedList href="services" className="unorderedList" onClick={() => scrollToSection("services")}>Services</UnorderedList>
          <UnorderedList href="about-us" className="unorderedList" onClick={() => scrollToSection("about-us")}>About Us</UnorderedList>
          <UnorderedList href="contact-us" className="unorderedList" onClick={() => scrollToSection("contact-us")}>Contact Us</UnorderedList>
        </DesktopList>
      </Navbar>
      {/* Mobile Menu */}
      {show && (
        <MobileList>
          <UnorderedList href="/" className="unorderedList active" onClick={() => scrollToSection("home")}>Home</UnorderedList>
          <UnorderedList href="services" className="unorderedList" onClick={() => scrollToSection("services")}>Services</UnorderedList>
          <UnorderedList href="about-us" className="unorderedList" onClick={() => scrollToSection("about-us")}>About Us</UnorderedList>
          <UnorderedList href="contact-us" className="unorderedList" onClick={() => scrollToSection("contact-us")}>Contact Us</UnorderedList>
        </MobileList>
      )}
      <Linebreak>
        <hr />
      </Linebreak>
    </Container>
  );
}

export default Header;
