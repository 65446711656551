import React from "react";
import { MdCopyright } from "react-icons/md";
import { AiFillFacebook, AiOutlineInstagram, AiFillLinkedin, AiFillYoutube, AiOutlineTwitter} from "react-icons/ai";
import { Container, Footcopyright, Footercopyright, FooterDiv, FooterLine, FooterLineDiv, FooterLink, FooterLinkDiv, FooterlinkIcon1, FooterlinkIcon2, FooterlinkIcon3, FooterlinkIcon4, FooterlinkIcon5, FooterPermission, FooterPermit, FooterPermitDiv, FooterpermitLinks, FooterPermitProp, FooterPermitTrade, Permitlink, PermitlinkList1, PermitlinkList2, PermitlinkList3, PermitlinkList4, PermitlinkList5 } from "./footer.styles";

function Footer() {
  return (
    <Container>
      <FooterDiv>
        <FooterLine></FooterLine>
        <FooterLineDiv>
          <FooterPermission>
            <Footercopyright>
              <Footcopyright>
                <MdCopyright />
              </Footcopyright>
              <FooterPermit> 2022 BrightLife Enhancement Services.</FooterPermit>
            </Footercopyright>
            <FooterpermitLinks>
              <PermitlinkList1>
                <Permitlink href="#">Contact Information</Permitlink>
              </PermitlinkList1>
              <PermitlinkList2>
                <Permitlink href="#">Legal Information</Permitlink>
              </PermitlinkList2>
              <PermitlinkList3>
                <Permitlink href="#">Improve this site</Permitlink>
              </PermitlinkList3>
              <PermitlinkList4>
                <Permitlink href="#">Manage your tracker settings</Permitlink>
              </PermitlinkList4>
              <PermitlinkList5>
                <Permitlink href="#">Projects</Permitlink>
              </PermitlinkList5>
            </FooterpermitLinks>
          </FooterPermission>
          <FooterLink>
            <FooterLinkDiv>
            <FooterlinkIcon1 href="https://web.facebook.com/profile.php?id=61551444698635">
              <AiFillFacebook />
            </FooterlinkIcon1>
              </FooterLinkDiv>
            <FooterLinkDiv>
              <FooterlinkIcon2 href="https://www.instagram.com/blesomhc/">
              <AiOutlineInstagram />
            </FooterlinkIcon2>
            </FooterLinkDiv>
            <FooterLinkDiv>
              <FooterlinkIcon3 href="https://www.linkedin.com/in/brightlife-enhancement-services-155b0a2a8/">
              <AiFillLinkedin />
            </FooterlinkIcon3>
            </FooterLinkDiv>
            <FooterLinkDiv>
              <FooterlinkIcon4 href="https://www.youtube.com/@blesomhc">
              <AiFillYoutube />
            </FooterlinkIcon4>
            </FooterLinkDiv>
            <FooterLinkDiv>
              <FooterlinkIcon5 href="https://twitter.com/BLESOMHC?t=ORTbOzVi3ydX0LTxBUZG1A&s=08">
              <AiOutlineTwitter />
            </FooterlinkIcon5>
            </FooterLinkDiv>
          </FooterLink>
        </FooterLineDiv>
        <FooterPermitDiv>
          <FooterPermitTrade>
            BrightLife Enhancement services is a registered
            trademarks.
          </FooterPermitTrade>
          <FooterPermitProp>
            All other trademarks are the property of their respective owners.
          </FooterPermitProp>
        </FooterPermitDiv>
      </FooterDiv>
    </Container>
  );
}

export default Footer;
