import styled from "styled-components";

export const ContactMain = styled.div`
    height: 100%;
`;

export const ContactRequest = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3rem 5rem;
    width: 100%;

   //tab-land
   @media only screen and (max-width: 1200px) {
   padding: 3rem;
  }

  //tab-port
  @media only screen and (max-width: 900px) {
   flex-direction: column;
   align-items: center;
   padding: 3rem 5rem;
  }
`;

export const RequestInfo = styled.div`
   width: 50%;
   padding-top: 3rem;

    //tab-port
  @media only screen and (max-width: 900px) {
   width: 100%;
  }
`;

export const RequestHead = styled.h1`
   padding-bottom: 2rem;
   font-weight: 500;

   //phone
  @media only screen and (max-width: 600px) {
   font-size: 20px;
  }
`;
export const RequestPara = styled.p`
   color: #777;
`;

export const RequestImage = styled.div`
   width: 50%;
   align-items: flex-end;
   padding-left: 3rem;

     //tab-port
  @media only screen and (max-width: 900px) {
   padding-left: 1.5rem;
   padding-top: 3rem;
  }

    //tab-port
    @media only screen and (max-width: 900px) {
   width: 100%;
   padding-left: 20%;
  }

   //phone
   @media only screen and (max-width: 600px) {
   padding-left: 5%;
  }
`;

export const RequestImg = styled.img`
   width: 95%;
   /* height: 400px; */

     //tab-land
  @media only screen and (max-width: 1200px) {
   height: 350px;
  }

  //tab-port
  @media only screen and (max-width: 900px) {
   height: 300px;
   width: 70%;
  }

  //phone
  @media only screen and (max-width: 600px) {
   height: 250px;
   width: 90%;
  }
`;