import React, { useState, useEffect } from 'react'
import { BiArrowFromBottom } from "react-icons/bi";
import styles from './scrollToTop.module.scss';


const ScrollToTop = () => {
    const [scrollTop, setScrollTop] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                setScrollTop(true);
            } else {
                setScrollTop(false);
            }
        });
    }, []);

    const bottomToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <div>
            {scrollTop && (
                <button onClick={bottomToTop} className={styles['backToTop']}>
                    <BiArrowFromBottom />
                </button>
            )}
        </div>
    )
};

export default ScrollToTop;
