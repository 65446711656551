import React from 'react'
import community from '../../../assets/img/community.jpg'
import { AcceptanceContainer, AcceptanceImage, AcceptanceImg, AcceptancePara, AcceptanceSpan, AcceptanceText } from './acceptance.styles'

const Acceptance = () => {
  return (
    <AcceptanceContainer>
        <AcceptanceImage>
        <AcceptanceImg url={community} alt='community image' />
        </AcceptanceImage>
        <AcceptanceText>
            <AcceptancePara><AcceptanceSpan>BrightLife Enhancement Services'</AcceptanceSpan> outpatient mental health clinic offers
            mental health services to individuals who do not require 24-hour care and can live in the community.
            Our primary goal is to provide assessment, diagnosis, treatment, and support for individuals with mental health disorders. <br />
            We accept referrals for services from other 
            healthcare providers, family members, and consumers. To be accepted and/or admitted into 
            the Psychiatric Rehabilitation Program, individuals must be under the care of a 
            psychiatric and/or psychotherapist.</AcceptancePara>
        </AcceptanceText>
    </AcceptanceContainer>
  )
}

export default Acceptance