import { AiFillLike, AiFillFacebook, AiOutlineInstagram, AiFillLinkedin, AiFillYoutube, AiOutlineTwitter} from "react-icons/ai";
import React, { useEffect, useState } from "react";
import userImg from "../../assets/img/brightlife.png";

import {
  Container,
  Discbar,
  Discfollow,
  Discicon,
  Disclink,
  Disclist,
  Discnav,
  DiscnavIcon,
  DiscnavIconbox,
  DiscnavImg,
  DiscnavNotify,
  DiscnavUser,
  DiscnavUserspan,
  DiscncertImg,
} from "./discount.styles";

function Discount() {

  const [likes, setLikes] = useState(0);
  const localStorageKey = 'likes';


  const handleLikeClick = () => {
    const updatedLikes = likes + 1;
    setLikes(updatedLikes);
    localStorage.setItem(localStorageKey, updatedLikes.toString());
  };

  useEffect(() => {
    const storedLikes = localStorage.getItem(localStorageKey);
    if (storedLikes) {
      setLikes(parseInt(storedLikes));
    }
  }, []);
  
  

  return (
    <Container>
      <Discbar>
        <Discfollow>Follow Us:</Discfollow>
        <Disclist>
          <Discicon>
            <Disclink href="https://web.facebook.com/profile.php?id=61551444698635">
              <AiFillFacebook />
            </Disclink>
          </Discicon>
          <Discicon>
            <Disclink href="https://www.instagram.com/blesomhc/">
              <AiOutlineInstagram />
            </Disclink>
          </Discicon>
          <Discicon>
            <Disclink href="https://www.linkedin.com/in/brightlife-enhancement-services-155b0a2a8/">
              <AiFillLinkedin />
            </Disclink>
          </Discicon>
          <Discicon>
            <Disclink href="https://www.youtube.com/@blesomhc">
              <AiFillYoutube />
            </Disclink>
          </Discicon>
          <Discicon>
            <Disclink href="https://twitter.com/BLESOMHC?t=ORTbOzVi3ydX0LTxBUZG1A&s=08">
              <AiOutlineTwitter />
            </Disclink>
          </Discicon>
        </Disclist>
        <Discnav>
          <DiscnavIconbox class="user-nav__icon-box">
            <DiscnavIcon class="user-nav__icon">
              <AiFillLike onClick={handleLikeClick}/>
            </DiscnavIcon>
            <DiscnavNotify>{likes}</DiscnavNotify>
          </DiscnavIconbox>
          <DiscnavIconbox class="user-nav__icon-box">
            <div>
              <DiscncertImg url={"https://res.cloudinary.com/dftu6mjmt/image/upload/v1678349662/Brightlife/certify1_p0w9fc.jpg"} alt="User photo" />
            </div>
          </DiscnavIconbox>
          <DiscnavUser>
            <DiscnavImg url={userImg} alt="User photo" />
            <DiscnavUserspan>BrightLife</DiscnavUserspan>
          </DiscnavUser>
        </Discnav>
      </Discbar>
    </Container>
  );
}

export default Discount;
